body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 25px !important;
}

.ace_editor, .toolbar {
    border: 1px solid lightgray;
    margin: auto;
    width: 100%;
} 
.ace_editor {
    height: 200px;
}

#area {
  margin:10px;
  height:200px;
}

.circle {
    border: 2px solid black;
    background-color: white;
    height: 20px;
    border-radius:50%;
    width: 20px;
    margin-left: -1px;
    margin-right: -1px;
    position: relative;
}
.circle>.square {
    position: absolute;
    background-color: black;
    width:8px;
    height:8px;
}
.circle:nth-child(2)>.square {
    bottom:0;
    right:0;
    border-bottom-right-radius:100%;
}
.circle:nth-child(3)>.square {
    bottom:0;
    border-bottom-left-radius:100%;
}
.circle:nth-child(4)>.square {
    right:0;
    border-top-right-radius:100%;
}
.circle:nth-child(5)>.square {
    border-top-left-radius:100%;
}
.fourblock {
  float:left;
  margin-right: 3px;
  margin-bottom: 1px;
}
.fourblock>.centerblack{
  position:relative;
  top:29px;
  left:11px;
  background-color: black;
  width:20px;
  height:20px;
  z-index:-1;
  margin-top:-20px;
}
.fourblock>.circle{
  float:left;
}
.fourblock>.circle:nth-child(4){
  clear:left;
}
.stepperrow {
  clear:both;
}
